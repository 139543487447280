import { StaticQuery, graphql } from 'gatsby';

import { IndexPage } from '../pageTemplates/index.js';
import { LanguageContext } from 'common/src/contexts/LanguageContext';
import React from 'react';
import { withPreview } from 'gatsby-source-prismic-graphql';

const seoDataQuery = graphql`
  query {
    prismic {
      allLanding_pages(lang: "en-gb") {
        edges {
          node {
            ...IndexPageContent
          }
        }
      }
    }
  }
`;

export default () => {
  return (
    <LanguageContext.Provider value={{ lang: 'en' }}>
      <StaticQuery
        query={`${seoDataQuery}`}
        render={withPreview(IndexPage, seoDataQuery)}
      />
    </LanguageContext.Provider>
  );
};
